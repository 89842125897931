import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Aos from "aos";
import "aos/dist/aos.css";
import WhoUs from "./components/whoUs/WhoUs";
import Collections from "./components/Collections/Collections";
import Worke from "./components/Worke/Worke";
import Partner from "./components/Partner/Partner";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { New } from "./components/New/New";
import New2 from "./components/New2/New2";
import Masar from "./components/Masar/Masar";
import Tahil from "./components/Tahil/Tahil";
import Tamkin from "./components/Tamkin/Tamkin";
import 'react-tooltip/dist/react-tooltip.css'


function App() {
  React.useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 1000,
      // easing:'ease-in-cubic'
      easing: "ease-in-out",
    });
  });

  return (
    <Router>
      <div className="App w-full">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/who-us" element={<WhoUs />} />
          <Route path="/collection" element={<Collections />} />
          <Route path="/workes" element={<Worke />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/khadamat" element={<New />} />
          <Route path="/الاستدامة" element={<New2 />} />
          <Route path="/مسار التأسيس" element={<Masar />} />
          <Route path="/مسار التأهيل" element={<Tahil />} />
          <Route path="/مسار التمكين" element={<Tamkin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
