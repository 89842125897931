import React, { useEffect, useState } from "react";
import "./Home.css";
import Clouds from "../../assets/Clouds.png";
import Clouds2 from "../../assets/Clouds2.png";
import Clouds3 from "../../assets/Clouds3.png";
import planet from "../../assets/Earth.png";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip'

const Home = () => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(true);
    useEffect(() => {
      const timeout = setTimeout(()=> {
        setIsTooltipOpen(false)
      },5000)
    
      return () => {
        clearTimeout(timeout)
      }
    }, [])
    
  return (
    <div className="home h-screen w-full overflow-hidden">
      <div className="z-30 fixed top-0 left-0  w-full  md:px-[100px] px-[40px] md:py-12 py-6   flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-bold">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 this-new font-light">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className="relative left-0 h-screen w-full top-0">
        <div className="  w-full absolute h-full flex justify-center">
          <img
            src={Clouds}
            alt=""
            className="absolute top-[25%] "
            data-aos="zoom-out"
          />
          <img
            src={planet}
            alt=""
            className="absolute top-[30%] "
            data-aos="zoom-out"
          />
          <img
            src={Clouds2}
            alt=""
            className="absolute top-[40%] "
            data-aos="zoom-out"
            data-aos-easing="cubic-bezier(0.645, 0.045, 0.355, 1)"
          />
          <img
            src={Clouds3}
            alt=""
            className="absolute top-[-50px] "
            data-aos="zoom-in"
          />
        </div>
        <div className="absolute w-full flex flex-col items-center justify-center gap-2 bottom-[120px] text-white">
          <h1
            data-aos-delay="400"
            className=" text-center do xl:text-6xl md:text-5xl text-2xl"
            data-aos="fade-up"
          >
            حُلول مُبتكــــــــــــــــــــــــــرة
            <br /> لِتنمية مُستدامـــــــــة
          </h1>
          <p
            className="xl:text-2xl md:text-xl text-lg font-bold mb-7"
            data-aos-delay="700"
            data-aos="fade-up"
          >
            نحو قطاع ريادي… مُمكّن ومتنامي
          </p>
          <p
            className="xl:text-3xl md:text-xl text-lg font-bold home-this "
            data-aos-delay="800"
            data-aos="fade-up"
          >
            تابع للأسفل
          </p>
        </div>
        <div className="absolute w-full flex justify-center items-center bottom-[80px]">
          <Link to="/who-us">
            <div className="p-2 rotate-45 border-r-white border-r-4 border-b-white border-b-4 cursor-pointer">
              <div className="p-2 border-r-[#ffffff7e] border-r-4 border-b-[#ffffff7e] border-b-4"></div>
            </div>
          </Link>
        </div>
        <div  className="absolute w-[2px] h-screen bg-[#90B528] left-10 z-50"> 
</div>
        <Link to="/who-us">
          <div data-tooltip-id="home-scroll" data-tooltip-content="اضغط هنا" className="z-50 absolute md:w-[34px] md:h-[34px] w-[20px] h-[20px] bg-[#90B528] md:left-[25px] left-[31px] top-[10%] rotate-45"></div>
        {/* <Tooltip className="z-[22222]" place={"right"} isOpen={isTooltipOpen} id="home-scroll" /> */}
        </Link>
      </div>
    </div>
  );
};

export default Home;
