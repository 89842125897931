import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.svg";
import message from "../../assets/Message.png";
import qii from "../../assets/Group 10.svg";
import vision from "../../assets/Vision.png";
import hend from "../../assets/haind.svg";
import mbrouk from "../../assets/mbrouk.svg";
import saar from "../../assets/sarokh.svg";
import kaba from "../../assets/kaba.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Collections.css";
import { Tooltip } from "react-tooltip";

function Collections() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const handleOne = () => {
    setOne(true);
    setTwo(false);
    setThree(false);
  };
  const handleTwo = () => {
    setOne(false);
    setTwo(true);
    setThree(false);
  };
  const handleThree = () => {
    setOne(false);
    setTwo(false);
    setThree(true);
  };
  useEffect(() => {
    setOne(true);
  }, []);

  return (
    <div className="home  min-h-screen  w-full ">
      <div className="z-30 fixed top-0 left-0 w-full  md:px-[100px] px-[40px]   py-3 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src={logo} alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-light">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 this-new ">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" relative top-0 w-full min-h-screen pb-16">
        <div className=" w-full top-[85%] flex items-center  justify-center">
          <div className="holder  flex items-center justify-between w-[75%] bg-[#FFFFFFBF] px-12 rounded-[19px] absolute top-[85%]  ">
            <h1
              className={`${
                one
                  ? "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25417C] font-bold  border-b-[5px] border-b-[#90B528]"
                  : "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25407c4a] font-bold"
              }`}
              onClick={handleOne}
            >
              رسالتنا
            </h1>
            <h1
              className={`${
                two
                  ? "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25417C] font-bold  border-b-[5px] border-b-[#90B528]"
                  : "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25407c4a] font-bold "
              }`}
              onClick={handleTwo}
            >
              رؤيتنا
            </h1>
            <h1
              className={`${
                three
                  ? "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25417C] font-bold  border-b-[5px] border-b-[#90B528]"
                  : "xl:text-3xl md:text-xl text-lg cursor-pointer py-8 text-[#25407c4a] font-bold"
              }`}
              onClick={handleThree}
            >
              قيمنا
            </h1>
          </div>
        </div>
        {/* qiyam */}
        <div
          className={`absolute  w-[80%] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] h-[60%] m  b-36   ${
            one ? "flex md:flex-row flex-col" : "hidden"
          } justify-between items-center `}
        >
          <div className="xl:w-auto md:w-1/2 w-full">
            <div className="flex  items-center gap-3 mb-10">
              <h1 className=" text-white xl:text-5xl text-4xl font-bold mess">
                رسالتنا
              </h1>
              <img src={qii} alt="" className=" logo" />
            </div>
            <p className="text-white 2xl:text-3xl xl:text-xl md:text-xl text-lg per">
              أن نكون القلب النابض بالحياة (الودق المستمر) لاستدامة
              <br /> كيانات القطاع غير الربحي والذي يستبشر القطاع بوجوده
            </p>
          </div>
          <img src={message} alt="" className="once  md:w-1/2 w-full " />
        </div>
        {/* vision */}
        <div
          className={`absolute  w-[80%] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] h-[60%]   ${
            two ? "flex md:flex-row flex-col" : "hidden"
          } justify-between items-center `}
        >
          <div className="xl:w-auto  md:w-1/2 w-full">
            <div className="flex items-center gap-3 mb-10">
              <h1 className=" text-white xl:text-5xl text-4xl font-bold mess">
                رؤيتنا
              </h1>
              <img src={qii} alt="" className="logo" />
            </div>
            <p className="text-white 2xl:text-3xl xl:text-xl md:text-xl text-lg per">
              نعمل بشغف في تمكين القطاع غير الربحي
              وتقديــــــــــــــــــــــــــــــــم خدمات عالية الجودة للإسهام
              بفاعلية في استدامة كيانــــات القطاع من خلال كوادر مؤهلة وخبراء
              محترفين وشراكـــــــات استراتيجية
            </p>
          </div>
          <img src={vision} alt="" className="look  w-1/2" />
        </div>
        {/* message */}
        <div
          className={`absolute  w-[80%] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] sm:h-[80%] h-[90%]    ${
            three ? "flex " : "hidden"
          } justify-between items-center`}
        >
          <div className=" pb-12">
            <div className="flex items-center gap-3 mb-8">
              <h1 className=" text-white xl:text-5xl text-4xl font-bold mess">
                قيمنا
              </h1>
              <img src={qii} alt="" />
            </div>
            <div className="text-white xl:text-3xl md:text-xl text-lg flex md:flex-nowrap flex-wrap  gap-6 items-center md:mb-[-100px] mb-[-0px] w-full">
              <div className="card flex justify-center items-center flex-col w-[40%] for">
                <img src={hend} alt="" className=" mb-8 md:h-auto h-12" />
                <div className=" text-center">
                  <h1 className=" xl:text-3xl md:text-xl text-lg font-bold text-[#90B528] mb-3">
                    المصداقية
                  </h1>
                  <p className="2xl:text-3xl xl:text-xl md:text-lg text-base">
                    نحرص على الوفاء التام بثقة شركائنا بنا
                  </p>
                </div>
              </div>
              <div className="card flex justify-center items-center flex-col w-[40%] three ">
                <img src={mbrouk} alt="" className=" mb-8 md:h-auto h-12" />
                <div className=" text-center">
                  <h1 className=" xl:text-3xl md:text-xl text-lg font-bold text-[#90B528] mb-3">
                    التميز
                  </h1>
                  <p className="2xl:text-3xl xl:text-xl md:text-lg text-base">
                    شغوفون بالقيادة و الريادة و التجديد لا التقليد
                  </p>
                </div>
              </div>
              <div className="card flex justify-center items-center flex-col w-[40%] two ">
                <img src={saar} alt="" className=" mb-8 md:h-auto h-12" />
                <div className=" text-center">
                  <h1 className=" xl:text-3xl md:text-xl text-lg font-bold text-[#90B528] mb-3">
                    الابتكار و الابداع{" "}
                  </h1>
                  <p className="2xl:text-3xl xl:text-xl md:text-lg text-base">
                    نوقن بأن التفكير خارج الصندوق منطلق التغيير و التطوير
                  </p>
                </div>
              </div>
              <div className="card flex justify-center items-center flex-col w-[40%] one ">
                <img src={kaba} alt="" className=" mb-8 md:h-auto h-12" />
                <div className=" text-center">
                  <h1 className=" xl:text-3xl md:text-xl text-lg font-bold text-[#90B528] mb-3">
                    الاحترافية
                  </h1>
                  <p className="2xl:text-3xl xl:text-xl md:text-lg text-base">
                    نسعى لتقديم خدمات مهنية منهجية متخصصة
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" absolute w-[2px] h-full bg-[#90B528] left-10"> </div>
        <Link to="/workes">
          <div
            data-tooltip-id="collection-scroll"
            data-tooltip-content="اضغط هنا"
            className=" absolute md:w-[34px] md:h-[34px] w-[20px] h-[20px] bg-[#90B528] md:left-[25px] left-[31px] top-[40%] rotate-45 cap2"
          ></div>
          {/* <Tooltip  className="z-[22222]" place={"right"} isOpen={isTooltipOpen}   id="collection-scroll" /> */}
        </Link>
      </div>
    </div>
  );
}

export default Collections;
