// Worke.js

import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.svg";
import water from "../../assets/Image -1.png";
import qii from "../../assets/Group 10.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Worke.css";

// Import Swiper styles
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function Worke() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="relative home min-h-screen w-full">
      <div className="who-us-header w-full z-[1000] md:px-[100px] px-[40px]  f  py-3 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src={logo} alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className=" text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg this-new">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className=" text-white cursor-pointer md:text-3xl text-lg p-2   font-light">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className="w-full left-0 flex justify-center items-center py-3 md:px-0 px-[25px]">
        <div className="flex flex-col lg:flex-row lg:gap-0 gap-12 fade-in-from-left  p-10 rounded-lg  bg-[#FFFFFFBF] md:w-[80%]   justify-between">
          <div className="xl:w-1/3 lg:w-2/5 w-full flex flex-col justify-center">
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className="text-[#25417C] font-bold md:text-3xl text-2xl mb-5">
              سقيا الماء لعام 2023م
            </h1>
            <p className="text-[#707070] font-bold md:text-xl text-base max-w-prose">
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية
              الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا
              الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000
              عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img
            src={water}
            alt=""
            className="xl:w-1/3 lg:w-2/5 w-full rounded-lg md:rounded-none"
          />
        </div>
        {/* <Swiper
      spaceBetween={20}
      slidesPerView={1.2}
      direction="horizontal" 
     ltr={false}
     rtl={true}
     Autoplay ={true}
  
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      className=' flex justify-center items-center w-full '
    >
      <SwiperSlide className=' fade-in-from-left'> 
      <div className='flex flex-col md:flex-row fade-in-from-left p-10 rounded-lg bg-opacity-50 bg-[#FFFFFFBF] md:w-[80%] h-[70%] justify-between'>
          <div className='md:w-1/3 flex flex-col justify-center'>
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className='text-[#25417C] font-bold text-4xl mb-5'>
              سقيا الماء لعام 2023م
            </h1>
            <p className='text-[#707070] font-bold text-2xl max-w-prose'>
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000 عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img src={water} alt="" className='md:w-[40%] rounded-lg md:rounded-none' />
        </div></SwiperSlide>
      <SwiperSlide> <div className='flex flex-col md:flex-row p-10 rounded-lg bg-opacity-50 bg-[#FFFFFFBF] md:w-[80%] h-[70%] justify-between'>
          <div className='md:w-1/3 flex flex-col justify-center'>
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className='text-[#25417C] font-bold text-4xl mb-5'>
              سقيا الماء لعام 2023م
            </h1>
            <p className='text-[#707070] font-bold text-2xl max-w-prose'>
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000 عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img src={water} alt="" className='md:w-[40%] rounded-lg md:rounded-none' />
        </div></SwiperSlide>
      <SwiperSlide> <div className='flex flex-col md:flex-row p-10 rounded-lg bg-opacity-50 bg-[#FFFFFFBF] md:w-[80%] h-[70%] justify-between'>
          <div className='md:w-1/3 flex flex-col justify-center'>
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className='text-[#25417C] font-bold text-4xl mb-5'>
              سقيا الماء لعام 2023م
            </h1>
            <p className='text-[#707070] font-bold text-2xl max-w-prose'>
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000 عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img src={water} alt="" className='md:w-[40%] rounded-lg md:rounded-none' />
        </div></SwiperSlide>
      <SwiperSlide> <div className='flex flex-col md:flex-row p-10 rounded-lg bg-opacity-50 bg-[#FFFFFFBF] md:w-[80%] h-[70%] justify-between'>
          <div className='md:w-1/3 flex flex-col justify-center'>
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className='text-[#25417C] font-bold text-4xl mb-5'>
              سقيا الماء لعام 2023م
            </h1>
            <p className='text-[#707070] font-bold text-2xl max-w-prose'>
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000 عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img src={water} alt="" className='md:w-[40%] rounded-lg md:rounded-none' />
        </div></SwiperSlide>
      <SwiperSlide> <div className='flex flex-col md:flex-row p-10 rounded-lg bg-opacity-50 bg-[#FFFFFFBF] md:w-[80%] h-[70%] justify-between'>
          <div className='md:w-1/3 flex flex-col justify-center'>
            <div className="flex items-center gap-3 mb-12">
              <h1 className="text-[#25417C] text-5xl font-bold">انجازاتنا</h1>
              <img src={qii} alt="" />
            </div>
            <h1 className='text-[#25417C] font-bold text-4xl mb-5'>
              سقيا الماء لعام 2023م
            </h1>
            <p className='text-[#707070] font-bold text-2xl max-w-prose'>
              مشاريع سقيا الماء تم تنفيذها للجمعيات (جمعية الإحســــان لرعاية الإنسان - جمعية تنمية القرى الخيرية – جمعيــة كرم)وذلك لسقيا الحجاج والمعتمرين في موسم الحج والعمرة بتوزيع أكثر من 2,000,000 عبوة ووجبة 1,000,0000 قدمت لضيوف الرحمن
            </p>
          </div>
          <img src={water} alt="" className='md:w-[40%] rounded-lg md:rounded-none' />
        </div></SwiperSlide>
    </Swiper> */}
      </div>
      <div className=" absolute w-[2px] h-full bg-[#90B528] top-0 left-10">
        {" "}
      </div>
      <Link to="/partner">
        <div
          data-tooltip-id="work-scroll"
          data-tooltip-content="اضغط هنا"
          className=" absolute md:w-[34px] md:h-[34px] w-[20px] h-[20px] bg-[#90B528] md:left-[25px] left-[31px] top-[50%] rotate-45 cap3"
        ></div>
        {/* <Tooltip  className="z-[22222]" place={"right"} isOpen={isTooltipOpen}  id="work-scroll" /> */}
      </Link>
    </div>
  );
}

export default Worke;
