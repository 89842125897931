import React from "react";
import { Link } from "react-router-dom";
import qii from "../../assets/Group 10.svg";
import back from "../../assets/Group -3.svg";
import "./Masar.css";
import whats from "../../assets/whatsup.svg";
import message from "../../assets/message.svg";
import meadia from "../../assets/s-meadia.svg";
import logo00 from "../../assets/logo2.svg";
function Masar() {
  return (
    <div className=" w-full">
      <div className="header w-full  md:px-[100px] px-[25px] md:py-12 py-6 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-light">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 this-new ">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" w-full kkkk relative overflow-hidden">
        <img
          src={back}
          alt=""
          className=" absolute left-[-200px] z-[-1] top-[0px]"
        />
        <div className="  w-full flex items-center gap-3 mt-[80px] md:pr-[150px] pr-[50px]">
          <Link to={"/khadamat"}>
            <p className=" text-[#707070] text-xl font-bold">الخدمات</p>
          </Link>
          <div className=" rotate-45 p-1 border-l-2 border-l-[#707070] border-b-2 border-b-[#707070] "></div>
          <p className=" text-[#707070] text-xl font-bold">مسار التأسيس</p>
        </div>
        <div className="flex items-center gap-3 mb-[60px]  mt-[54px]">
          <h1 className=" text-[#25417C] md:text-5xl text-4xl font-bold md:pr-[150px] pr-[50px]">
            مسار التأسيس
          </h1>
          <img src={qii} alt="" />
        </div>
        <div className="cards flex items-center justify-center gap-[40px] mb-28 lg:flex-nowrap flex-wrap px-4">
          <div className="AA lg:w-1/3 w-[450px] h-[400px]  bg-[#95C13D] pt-4 pb-1 rounded-[16px] px-[2px] ">
            <div className=" w-full h-full bg-white rounded-[16px] flex flex-col justify-between py-4 px-2">
              <h1 className=" xl:text-3xl lg:text-2xl md:text-xl font-bold text-[#25417C] mr-[40px]   ">
                الاسشارات الفنية
              </h1>
              <div className=" parg">
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px] mr-[20px] "></span>
                  صياغة فكرة إنشاء الجمعية من الخيال إلى الواقع
                </p>
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
                  بتوجهات المركز الوطني
                </p>
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
                  مسح ميداني للجمعيات المماثلة للفكرة
                </p>
              </div>
              <div className=" w-full flex items-center justify-center mt-16">
                <div className=" hh cursor-pointer relative overflow-hidden border-[5px] border-[#25417C] w-[270px]  h-[48px] text-[#25417C]  flex items-center justify-center rounded-[20px]">
                  اشترك في الخدمة
                </div>
              </div>
            </div>
          </div>
          <div className="AA lg:w-1/3 w-[450px] h-[400px] bg-[#95C13D] pt-4 pb-1 rounded-[16px] px-[2px] ">
            <div className=" w-full h-full bg-white rounded-[16px] flex flex-col justify-between py-4 px-2">
              <h1 className=" xl:text-3xl lg:text-2xl md:text-xl font-bold text-[#25417C] mr-[40px]  mb-[40px] ">
                الاستشارات القانونية
              </h1>
              <div className=" parg">
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px] mr-[20px] "></span>
                  تقديم استشارات للمؤسسين حول الأحكام والقواعد المنظمة لتأسيس
                  الجمعيات
                </p>
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
                  تقديم استشارات بحلول قانونية في انشاء الكيانات غير الربحية
                </p>
              </div>
              <div className=" w-full flex items-center justify-center mt-16">
                <div className=" hh cursor-pointer relative overflow-hidden border-[5px] border-[#25417C] w-[270px]  h-[48px] text-[#25417C]  flex items-center justify-center rounded-[20px]">
                  اشترك في الخدمة
                </div>
              </div>
            </div>
          </div>
          <div className="AA lg:w-1/3 w-[450px] h-[400px] bg-[#95C13D] pt-4 pb-1 rounded-[16px] px-[2px] ">
            <div className=" w-full h-full bg-white rounded-[16px] flex flex-col justify-between py-4 px-2">
              <h1 className=" xl:text-3xl lg:text-2xl md:text-xl font-bold text-[#25417C] mr-[40px]  mb-[40px] ">
                الاستشارات الادارية
              </h1>
              <div className=" parg">
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px] mr-[20px] "></span>
                  صياغة بيانات الأعضاء المؤسسين من مجلس الإدارة واعتمادها من
                  المركز الوطني
                </p>
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
                  إعداد الملفات الداعمة للموافقة على الانشاء والتأسيس
                </p>
                <p className=" xl:text-xl lg:text-lg text-base text-[#27A1D8] mb-2">
                  <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
                  متابعة إصدار الترخيص الرسمية
                </p>
              </div>
              <div className=" w-full flex items-center justify-center mt-16">
                <div className=" hh cursor-pointer relative overflow-hidden border-[5px] border-[#25417C] w-[270px]  h-[48px] text-[#25417C]  flex items-center justify-center rounded-[20px]">
                  اشترك في الخدمة
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="  w-full bg-[#25417C]  md:px-[100px] px-[50px] py-[49px] flex justify-between flex-wrap lg:gap-0 gap-8">
        <div className="lg:order-1 md:order-2 order-3 flex-1 flex flex-col justify-between">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تواصل معنا
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={whats} alt="" />
              <p className=" text-white">0567919186</p>
            </div>
            <div className=" flex items-center gap-3  mr-16">
              <img src={message} alt="" />
              <p className=" text-white">info@wadaq.sa</p>
            </div>
          </div>
          <div>
            <img src={logo00} alt="" className=" mb-[20px]" />
            <p className=" text-[#ffffff7e]">
              جميع الحقوق محفوظة مجموعة ودق للإستثمار الاجتماعي © 2024
            </p>
          </div>
        </div>
        <div className="lg:order-2 md:order-3 order-2 flex-1 flex flex-col justify-between md:items-center">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تابعنا على
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={meadia} alt="" />
              <p className=" text-white">@wadar8r</p>
            </div>
          </div>
          <div className=" flex gap-5">
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">
              الرئيسية{" "}
            </p>
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">الخدمات</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Masar;
