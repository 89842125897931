import React from "react";
import { Link } from "react-router-dom";
import qii from "../../assets/Group 10.svg";
import token from "../../assets/token.svg";
import back from "../../assets/the.svg";
import "./Tahil.css";
import whats from "../../assets/whatsup.svg";
import message from "../../assets/message.svg";
import meadia from "../../assets/s-meadia.svg";
import logo00 from "../../assets/logo2.svg";

function Tahil() {
  return (
    <div className=" w-full">
      <div className="header w-full  md:px-[100px] px-[40px]  md:py-12 py-6 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-light">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 this-new ">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" w-full kkkk relative overflow-hidden">
        <img
          src={back}
          alt=""
          className=" absolute left-[00px] z-[-1] top-[0px]"
        />
        <div className="  w-full flex items-center gap-3 mt-[80px] lg:pr-[150px] md:pr-[100px] pr-[50px]">
          <Link to={"/khadamat"}>
            <p className=" text-[#707070] text-xl font-bold">الخدمات</p>
          </Link>
          <div className=" rotate-45 p-1 border-l-2 border-l-[#707070] border-b-2 border-b-[#707070] "></div>
          <p className=" text-[#707070] text-xl font-bold">مسار التأهيل</p>
        </div>
        <div className="flex items-center gap-3 mb-[60px]  mt-[54px]">
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl  font-bold lg:pr-[150px] md:pr-[100px] pr-[50px] ">
            مسار التأهيل
          </h1>

          <img src={qii} alt="" />
        </div>
        <div className=" w-full">
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl font-semibold  lg:pr-[150px] md:pr-[100px] pr-[50px] mb-16">
            منظور الخدمة
          </h1>
          <p className=" text-[#707070] lg:pr-[150px] md:pr-[100px] px-[50px] font-semibold text-2xl xl:w-[40%] md:w-2/3 w-full  mb-16">
            خدمة تأهل الجمعيات الناشئة من بناء مواردها البشرية والمالية وفق
            معايير وأنظمة المركز الوطني لتنمية القطاع غير الربحي عن طريق تقديم
            خدمات أساسية تأهيلية لمدة 100 يوم
          </p>
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl font-semibold  lg:pr-[150px] md:pr-[100px] pr-[50px] mb-16">
            جميع الخدمات الــ 100 يوم
          </h1>
        </div>
        <div className="cards flex items-center justify-center  mb-28 w-full flex-col  xl:gap-[50px] gap-[25px] mt-12 ">
          <div className="cards1 flex xl:gap-[50px] gap-[25px] items-center justify-center flex-wrap">
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              التوجه الاستراتيجي
            </div>
            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              الموارد المالية
            </div>
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              الحسابات البنكية
            </div>
            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              قنوات التواصل
            </div>
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              الهوية البصرية
            </div>
            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              العنوان الوطني
            </div>
            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              الرئيسية
            </div>
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              الموارد البشرية
            </div>

            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              اللوائح والأنظمة
            </div>
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              كـــــــــــــود 700
            </div>

            <div className=" hh relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#25417C] text-[#25417C] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              التأمينات الاجتماعية
            </div>
            <div className="dd relative overflow-hidden p-[50px] h-[160px] w-[160px] border-[3px] border-[#3CAA49] text-[#3CAA49] font-bold rounded-[20px]  flex items-center justify-center text-center text-xl">
              المركز الوطني
            </div>
          </div>
          <div className="cards1 flex xl:gap-[50px] gap-[25px] items-center justify-center flex-wrap"></div>
          <div className="flex jj relative overflow-hidden items-center justify-center rounded-[20px] w-[200px] h-[50px] border-[4px] border-[#95C13D] text-[#95C13D] text-xl font-bold">
            خدمات الــ 100 يوم
          </div>
        </div>
      </div>
      <footer className="  w-full bg-[#25417C]  md:px-[100px] px-[50px] py-[49px] flex justify-between flex-wrap lg:gap-0 gap-8">
        <div className="lg:order-1 md:order-2 order-3 flex-1 flex flex-col justify-between">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:lg:text-5xl text-3xl text-3xl font-bold">
                تواصل معنا
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={whats} alt="" />
              <p className=" text-white">0567919186</p>
            </div>
            <div className=" flex items-center gap-3  mr-16">
              <img src={message} alt="" />
              <p className=" text-white">info@wadaq.sa</p>
            </div>
          </div>
          <div>
            <img src={logo00} alt="" className=" mb-[20px]" />
            <p className=" text-[#ffffff7e]">
              جميع الحقوق محفوظة مجموعة ودق للإستثمار الاجتماعي © 2024
            </p>
          </div>
        </div>
        <div className="lg:order-2 md:order-3 order-2 flex-1 flex flex-col justify-between md:items-center">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:lg:text-5xl text-3xl text-3xl font-bold">
                تابعنا على
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={meadia} alt="" />
              <p className=" text-white">@wadar8r</p>
            </div>
          </div>
          <div className=" flex gap-5">
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">
              الرئيسية{" "}
            </p>
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">الخدمات</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Tahil;
