import React, { useEffect, useState } from "react";
import logo from "../../assets/logo1.svg";
import logo1 from "../../assets/manarat.png";
import logo2 from "../../assets/alihsan.png";
import logo3 from "../../assets/waqf-ridha.png";
import logo4 from "../../assets/tanmia.png";
import logo5 from "../../assets/yanba.png";
import logo00 from "../../assets/logo2.svg";
import qii from "../../assets/Group 10.svg";
import whats from "../../assets/whatsup.svg";
import message from "../../assets/message.svg";
import meadia from "../../assets/s-meadia.svg";
import "./Partner.css";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function Partner() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="home relative bg-white min-h-screen  w-full">
      <div className="bg-white w-full z-[1000] md:px-[100px] px-[40px]  md:py-12 py-6 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src={logo} alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-[#25417c]  cursor-pointer ml-10 p-2 md:text-3xl text-lg this-new">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-[#25417c]  cursor-pointer md:text-3xl text-lg p-2   font-light">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" relative w-full  left-0 top-0 bg-white py-12">
        <div className=" w-full ">
          <h1 className=" text-center font-extrabold lg:text-7xl md:text-6xl text-4xl text-[#3CAA49] mb-[90px] col5">
            شركاء نجاحنا
          </h1>
          <div className=" w-full flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-between  2xl:px-[205px] lg-[75px] px-[50px]">
            <img
              src={logo1}
              alt=""
              className="xl:h-24 lg:h-20 md:h-16 col5 z-50 "
            />
            <img
              src={logo2}
              alt=""
              className="xl:h-24 lg:h-20 md:h-16 col5 z-50 "
            />
            <img
              src={logo3}
              alt=""
              className="xl:h-24 lg:h-20 md:h-16 col4 z-50 "
            />
            <img
              src={logo4}
              alt=""
              className="xl:h-24 lg:h-20 md:h-16 col5 z-50 "
            />
            <img
              src={logo5}
              alt=""
              className="xl:h-24 lg:h-20 md:h-16 col4 z-50 "
            />
          </div>
        </div>
        {/* Footer */}
      </div>
      <footer className="w-full bg-[#25417C] md:px-[100px] px-[50px] py-[49px] flex justify-between flex-wrap lg:gap-0 gap-8">
        <div className="lg:order-1 md:order-2 order-3 flex-1 flex flex-col justify-between">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تواصل معنا
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={whats} alt="" />
              <p className=" text-white">0567919186</p>
            </div>
            <div className=" flex items-center gap-3  mr-16">
              <img src={message} alt="" />
              <p className=" text-white">info@wadaq.sa</p>
            </div>
          </div>
          <div>
            <img src={logo00} alt="" className=" mb-[20px]" />
            <p className=" text-[#ffffff7e]">
              جميع الحقوق محفوظة مجموعة ودق للإستثمار الاجتماعي © 2024
            </p>
          </div>
        </div>
        <div className="lg:order-2 md:order-3 order-2 flex-1 flex flex-col justify-between md:items-center">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تابعنا على
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={meadia} alt="" />
              <p className=" text-white">@wadar8r</p>
            </div>
          </div>
          <div className=" flex gap-5">
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">
              الرئيسية{" "}
            </p>
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">الخدمات</p>
          </div>
        </div>
      </footer>
      <div className=" absolute w-[2px] h-full top-0  bg-[#90B528] left-10">
        {" "}
      </div>
      <Link to={"/"}>
        <div
          data-tooltip-id="partner-scroll"
          data-tooltip-content="اضغط هنا"
          className=" absolute md:w-[34px] md:h-[34px] w-[20px] h-[20px] bg-[#90B528] md:left-[25px] left-[31px] top-[90%] rotate-45 z-[22222] cap4"
        ></div>
        {/* <Tooltip   className="z-[22222]" place={"right"} isOpen={isTooltipOpen} id="partner-scroll" /> */}
      </Link>
    </div>
  );
}

export default Partner;
