import React from "react";
import { Link } from "react-router-dom";
import qii from "../../assets/Group 10.svg";
import back from "../../assets/the.svg";
import "./Tamkin.css";
import whats from "../../assets/whatsup.svg";
import message from "../../assets/message.svg";
import meadia from "../../assets/s-meadia.svg";
import logo00 from "../../assets/logo2.svg";
import med from "../../assets/doct.svg";
import edit from "../../assets/edit.svg";
import tv from "../../assets/tv.svg";
import ph from "../../assets/ph.svg";

function Tamkin() {
  return (
    <div className=" w-full">
      <div className="header w-full  md:px-[100px] px-[40px]  md:py-12 py-6 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-light">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 this-new ">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" w-full kkkk relative overflow-hidden">
        <img
          src={back}
          alt=""
          className=" absolute left-[00px] z-[-1] top-[0px]"
        />
        <div className="  w-full flex items-center gap-3 mt-[80px] lg:pr-[150px] md:pr-[100px] pr-[50px] ">
          <Link to={"/khadamat"}>
            <p className=" text-[#707070] text-xl font-bold">الخدمات</p>
          </Link>
          <div className=" rotate-45 p-1 border-l-2 border-l-[#707070] border-b-2 border-b-[#707070] "></div>
          <p className=" text-[#707070] text-xl font-bold">مسار التمكين</p>
        </div>
        <div className="flex items-center gap-3 mb-[60px]  mt-[54px]">
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl  font-bold lg:pr-[150px] md:pr-[100px] pr-[50px]  ">
            مسار التمكين
          </h1>

          <img src={qii} alt="" />
        </div>
        <div className=" w-full">
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl font-semibold  lg:pr-[150px] md:pr-[100px] pr-[50px]  mb-16">
            منظور الخدمة
          </h1>
          <p className=" text-[#707070] lg:pr-[150px] md:pr-[100px] pr-[50px]  font-semibold text-2xl xl:w-[40%] md:w-2/3 w-full  mb-16">
            خدمة تمكن الجمعيات والجهات غيرالربحية بتمية موادرها المالية بمسارات
            مختلفة
          </p>
          <div className=" parg  lg:pr-[150px] md:pr-[100px] pr-[50px]   mb-7">
            <p className=" text-xl text-[#707070] mb-2">
              <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px] mr-[20px] "></span>
              فتح حسابات في المنصات والمؤسسات المانحة وإدارتها
            </p>
            <p className=" text-xl text-[#707070] mb-2">
              <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
              إنشاء المتاجر الإلكترونية وإدارتها
            </p>
            <p className=" text-xl text-[#707070] mb-2">
              <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
              إعداد البرامج والمشاريع وإدارة تنفيذها
            </p>
            <p className=" text-xl text-[#707070] mb-2">
              <span className="inline-block px-[3px] py-[6px] border-t-[5px] border-t-[#95C13D] border-l-[5px] border-l-[#95C13D]  transform rotate-[-130deg] ml-[10px]  mr-[20px] "></span>
              استشارات وحلول للعلل والمشكلات عن طريق اخصائيين محترفين من عيادة
              تمكين
            </p>
          </div>
          <h1 className=" text-[#25417C] lg:text-5xl text-3xl font-semibold  lg:pr-[150px] md:pr-[100px] pr-[50px]  mb-16">
            جميع الخدمات الــ 100 يوم
          </h1>
        </div>
        <div className="cards flex items-center justify-center  mb-28 w-full gap-[30px] mt-12 flex-wrap ">
          <div className=" py-7 px-3 w-[400px] border-[#95C13D] border-[1px] flex items-center  justify-between flex-col rounded-[15px] h-[400px]">
            <h1 className=" text-3xl font-bold text-[#25417C]">عيادات تمكين</h1>
            <img src={med} alt="" />
            <div className="flex jj relative overflow-hidden items-center justify-center rounded-[20px] w-[350px] h-[50px] border-[4px] border-[#95C13D] text-[#95C13D] text-lg font-bold">
              احصل على جميع الاستشارات لجمعيتك
            </div>
          </div>
          <div className=" py-7 px-3 w-[400px] border-[#95C13D] border-[1px] flex items-center justify-between flex-col rounded-[15px] h-[400px]">
            <h1 className=" text-3xl font-bold text-[#25417C]">
              البرامج والمشاريع
            </h1>
            <img src={edit} alt="" />
            <div className="flex jj relative overflow-hidden items-center justify-center rounded-[20px] w-[350px] h-[50px] border-[4px] border-[#95C13D] text-[#95C13D]  text-lg font-bold">
              اصنع مشاريعك مع خبراء التمكين
            </div>
          </div>
          <div className=" py-7 px-3 w-[400px] border-[#95C13D] border-[1px] flex items-center  justify-between flex-col rounded-[15px] h-[400px]">
            <h1 className=" text-3xl font-bold text-[#25417C]">
              المنصات المانحة
            </h1>
            <img src={tv} alt="" />
            <div className="flex jj relative overflow-hidden items-center justify-center rounded-[20px] w-[350px] h-[50px] border-[4px] border-[#95C13D] text-[#95C13D] text-lg font-bold">
              بادر بتقديم مشاريعك في المنصات المانحة
            </div>
          </div>
          <div className=" py-7 px-3 w-[400px] border-[#95C13D] border-[1px] flex items-center  justify-between flex-col rounded-[15px] h-[400px]">
            <h1 className=" text-3xl font-bold text-[#25417C]">
              المنصات المانحة
            </h1>
            <img src={ph} alt="" />
            <div className="flex jj relative overflow-hidden items-center justify-center rounded-[20px] w-[350px] h-[50px] border-[4px] border-[#95C13D] text-[#95C13D] text-lg font-bold">
              بادر بتقديم مشاريعك في المنصات المانحة
            </div>
          </div>
        </div>
      </div>
      <footer className="  w-full bg-[#25417C]  md:px-[100px] px-[50px] py-[49px] flex justify-between flex-wrap lg:gap-0 gap-8">
        <div className="lg:order-1 md:order-2 order-3 flex-1 flex flex-col justify-between">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:lg:text-5xl text-3xl text-3xl font-bold">
                تواصل معنا
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={whats} alt="" />
              <p className=" text-white">0567919186</p>
            </div>
            <div className=" flex items-center gap-3  mr-16">
              <img src={message} alt="" />
              <p className=" text-white">info@wadaq.sa</p>
            </div>
          </div>
          <div>
            <img src={logo00} alt="" className=" mb-[20px]" />
            <p className=" text-[#ffffff7e]">
              جميع الحقوق محفوظة مجموعة ودق للإستثمار الاجتماعي © 2024
            </p>
          </div>
        </div>
        <div className="lg:order-2 md:order-3 order-2 flex-1 flex flex-col justify-between md:items-center">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:lg:text-5xl text-3xl text-3xl font-bold">
                تابعنا على
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={meadia} alt="" />
              <p className=" text-white">@wadar8r</p>
            </div>
          </div>
          <div className=" flex gap-5">
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">
              الرئيسية{" "}
            </p>
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">الخدمات</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Tamkin;
