import React from "react";
import { Link } from "react-router-dom";
import qii from "../../assets/Group 10.svg";
import info from "../../assets/info.svg";
import two from "../../assets/two.svg";
import "./New.css";
import whats from "../../assets/whatsup.svg";
import message from "../../assets/message.svg";
import meadia from "../../assets/s-meadia.svg";
import logo00 from "../../assets/logo2.svg";
export const New = () => {
  return (
    <div className=" w-full">
      <div className="header w-full  md:px-[100px] px-[40px]  md:py-12 py-6   flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg font-light">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="text-white cursor-pointer md:text-3xl text-lg p-2 font-bold">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>
      <div className=" w-full h-[100%] mb-[110px]">
        <div className="flex items-center gap-3 mb-[60px]  md:mt-[170px] mt-[70px]">
          <h1 className=" text-[#25417C] text-5xl font-bold md:pr-[205px] pr-[50px]">
            الخدمات
          </h1>
          <img src={qii} alt="" />
        </div>
        <div className="overflow-x-hidden flex items-center justify-between md:px-[100px] px-[40px]  md:flex-row md:gap-0 gap-12 flex-col">
          <Link className=" md:w-auto w-full" to={"/الاستدامة"}>
            <div className=" bg-[#95C13D] rounded-[65px] xl:w-[500px] lg:w-[350px] md:w-[275px] w-full  p-16 flex flex-col items-center justify-center cursor-pointer gap-3">
              <img src={info} alt="" className=" w-[80px] h-[80px]" />
              <h1 className=" 2xl:text-6xl md:text-3xl text-2xl font-bold text-white">
                الاستدامة{" "}
              </h1>
            </div>
          </Link>
          <span className=" md:w-auto w-full" to={"/"}>
            <div className=" bg-[#95C13D] rounded-[65px] xl:w-[500px] lg:w-[350px] md:w-[275px] w-full  p-16 flex flex-col items-center justify-center cursor-pointer gap-3">
              <img src={two} alt="" className=" w-[80px] h-[80px]" />
              <h1 className=" 2xl:text-6xl md:text-3xl text-2xl font-bold text-white">
                الحضانة{" "}
              </h1>
            </div>
          </span>
        </div>
      </div>
      <footer className="  w-full bg-[#25417C]  md:px-[100px] px-[50px] py-[49px] flex justify-between flex-wrap lg:gap-0 gap-8">
        <div className="lg:order-1 md:order-2 order-3 flex-1 flex flex-col justify-between">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تواصل معنا
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={whats} alt="" />
              <p className=" text-white">0567919186</p>
            </div>
            <div className=" flex items-center gap-3  mr-16">
              <img src={message} alt="" />
              <p className=" text-white">info@wadaq.sa</p>
            </div>
          </div>
          <div>
            <img src={logo00} alt="" className=" mb-[20px]" />
            <p className=" text-[#ffffff7e]">
              جميع الحقوق محفوظة مجموعة ودق للإستثمار الاجتماعي © 2024
            </p>
          </div>
        </div>
        <div className="lg:order-2 md:order-3 order-2 flex-1 flex flex-col justify-between md:items-center">
          <div className="">
            <div className="flex items-center gap-3 ">
              <img className="xl:h-auto h-6" src={qii} alt="" />
              <h1 className=" text-white xl:text-5xl text-3xl font-bold">
                تابعنا على
              </h1>
            </div>
            <div className=" h-2 w-[85px] bg-white mr-16 mt-[15px] "></div>
            <div className=" flex items-center gap-3 mb-5 mt-11  mr-16">
              <img src={meadia} alt="" />
              <p className=" text-white">@wadar8r</p>
            </div>
          </div>
          <div className=" flex gap-5">
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">
              الرئيسية{" "}
            </p>
            <p className=" text-[#ffffff7e] cursor-pointer text-xl">الخدمات</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
