import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.svg";
import qii from "../../assets/Group 10.svg";
import boy from "../../assets/Boy.png";
import back from "../../assets/Pattern.png";
import qaf from "../../assets/kaaf.svg";
import { Link, useNavigate } from "react-router-dom";
import "./WhoUs.css";
import { Tooltip } from "react-tooltip";

export default function WhoUs() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="relative h-fit w-full screen relative">
      <div className="who-us-header lg:absolute lg:top-0  w-full z-[1000] md:px-[100px] px-[40px]  md:py-12 py-6 flex justify-between flex-wrap">
        <div>
          <img className="md:h-auto h-12" src="/logowhite.svg" alt="" />
        </div>

        <div className="tags flex  items-center">
          <Link className=" md:w-auto w-full" to={"/"}>
            <h1 className="lg:text-[#25417c] text-white cursor-pointer ml-10 p-2 md:text-3xl text-lg this-new">
              الرئيسية
            </h1>
          </Link>
          <Link className=" md:w-auto w-full" to={"/khadamat"}>
            <h1 className="lg:text-[#25417c] text-white cursor-pointer md:text-3xl text-lg p-2   font-light">
              الخدمات
            </h1>
          </Link>
        </div>
      </div>

      <div className="relative left-0 h-screen w-full top-0 flex lg:flex-row flex-col-reverse overflow-hidden ">
        <div className="lg:w-[50%] w-full h-full flex md:items-center  items-start justify-center ">
          <img
            src={back}
            alt=""
            className=" absolute img top-[200px] 2xl:right-[250px] xl:right-[150px]"
          />
          <img
            src={boy}
            alt=""
            className=" absolute mt-10 z-50 img1 2xl:right-[400px] xl:right-[250px]"
          />
          <img
            src={qaf}
            alt=""
            className="absolute right-[30px] top-[200px]  img"
          />
        </div>
        <div className="lg:w-[50%] w-full flex items-center justify-center bg-white spes  z-20">
          <div className="w-[600px]">
            <div className="flex mb-4 gap-3 ">
              <h1
                className="text-[#25417c] lg:text-6xl text-4xl mr-2 nii"
                data-aos="fade-left"
                data-aos-delay="800"
              >
                من نحــــــــــن
              </h1>
              <img src={qii} alt="" className="mb-[-15px]" />
            </div>
            <div>
              <p
                className="text-[#707070] xl:text-2xl lg:text-lg  noo ml-6 xl:mr-0 mr-2 mb-8"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                مؤسسة تجارية تعمل بشغف واحترافيــــــــــــــــــة لتمكين كيانات
                القطاع غير الربحي ومساعدتها لتحقيق الأثر المستدام وفق أعلى
                معـــــــــــــــــــايير الجودة وأفضل الممارسات
                الممكنــــــــــــــــــــــــــــــــــــــــــة للمساهمة في
                تحقيق تطلعات رؤيـــــــــــــة 2030 من القطاع
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" absolute w-[2px] h-full bg-[#90B528] top-0 left-10 z-[2222] ">
        {" "}
      </div>
      <Link to="/collection">
        <div
          data-tooltip-id="who-us-scroll"
          data-tooltip-html='<span style="font-size:12px  ">اضغط هنا</span>'
          className="absolute md:w-[34px] md:h-[34px] w-[20px] h-[20px] bg-[#90B528] md:left-[25px] left-[31px] top-[30%] rotate-45 z-[2222222] cap1"
        ></div>
        <Tooltip
          className="z-[22222]"
          place={"bottom"}
          isOpen={isTooltipOpen}
          id="who-us-scroll"
        />
      </Link>
    </div>
  );
}
